import React, { useState, useEffect, useRef } from "react";
import awsLogo1 from "../assets/logo.png";
import awsLogo2 from "../assets/logo_2.png";
import copSpons2 from "../assets/cop_spons_2.png";
import org_1 from "../assets/org_1.jpeg";
import aws_white from "../assets/aws_white.png";
import org_3 from "../assets/org_3.jpg";
import org_4 from "../assets/org_4.jpg";
import org_5 from "../assets/org_5.jpg";
import akimi from "../assets/akimi_new.jpeg";
import techguru from "../assets/thetekgurus_logo.png";
import price from "../assets/price.jpg";
import aws from "../assets/aws_community_highlights.mp4";
import org_7 from "../assets/org_7.jpg";
import org_9 from "../assets/org_9.jpg";
import org_10 from "../assets/org_10.jpg";
import org_14 from "../assets/org_14.jpg";
import spons_2 from "../assets/spons_2.jpg";
import spons_5 from "../assets/spons_5.jpeg";
import spons_6 from "../assets/spons_6.jpg";
import one from "../assets/1.jpg";
import two from "../assets/2.jpg";
import three from "../assets/3.jpg";
import four from "../assets/4.jpg";
import five from "../assets/5.jpg";
import six from "../assets/6.jpg";
import seven from "../assets/7.jpg";
import brainbox from "../new_sponsors/Brainbox.jpg";
import cloudplexo from "../new_sponsors/CloudPlexo.jpg";
import creditWave from "../new_sponsors/CreditWave.jpg";
import decasio from "../new_sponsors/decasio-logo 1.png";
import Digitspot from "../new_sponsors/Digitspot logo.png";
import Feitian from "../new_sponsors/Feitian.png";
import PipeOps from "../new_sponsors/Pipeops 1 White.png";
import rubies from "../new_sponsors/rubies Logo white.png";
import TechMoon from "../new_sponsors/Techmoonshot white.png";
import Shutters from "../new_sponsors/shuttlers new logo2.png";
import cyril from "../new_speakers/Cyril Bandolo.png";
import monika from "../new_speakers/Monika Rajput.jpeg";
import justice from "../new_speakers/Justice Akpati.jpg";
import ayomide from "../new_speakers/Ayomide.jpg";
import jane from "../new_speakers/Witah Ngu Georjane.jpg";
import precious from "../new_speakers/Precious Okwu.jpg";
import samuel from "../new_speakers/Samuel Ayo.png";
import adijat from "../new_speakers/Adijat Oluwatosin Ojutomori.jpg";
import chisom from "../new_speakers/Chisom Jude.jpg";
import steven from "../new_speakers/Stephen Howell.jpg";
import quagraine from "../new_speakers/David Quagraine .jpg";
import yinka from "../new_speakers/Yinka Daramola.jpg";
import ekefre from "../new_speakers/David Ekefre.jpg";
import ewere from "../new_speakers/Ewere-Enyaosah.webp";
import oladele from "../new_speakers/Oladele Oluwadamilare.jpg";
import fikayo from "../new_speakers/Fikayo Olagunju.png";
import elizabeth from "../new_speakers/Elizabeth Adeotun.jpg";
import chibuike from "../new_speakers/Chibuike Nwachukwu.jpg";
import rachel from "../new_speakers/Rachel Adeshina.jpg";
import atomic_light from "../assets/atomic_light.png";
import "./Home.css";
import mayowa from "../assets/Mayowa Amao.jpeg";
import ewere2 from "../assets/ewere2.jpg";
import imoh from "../assets/Imoh Etuk.jpg";
import felix from "../assets/Felix Frank-Felix.jpg";
import akolagtec from "../assets/Akolagtec.jpg";
import arravo from "../assets/Arravo.png";
import datamellom from "../assets/Datamellon Logo 1 .png";
import devops from "../assets/devops.png";
import nycn from "../assets/NYCN LAGOS logo fn .png";
import fincra from "../assets/Fincra white full logo.png";

const bannerImages = [price, one, two, three, four, five, six, seven, aws];

const Home: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const sectionsRef = useRef<(HTMLElement | null)[]>([]);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  // Function to move to the next slide
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % bannerImages.length);
  };

  // Function to move to the previous slide
  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + bannerImages.length) % bannerImages.length
    );
  };

  // Function to go to a specific slide
  const goToSlide = (index: number) => {
    setCurrentIndex(index);
  };

  // Auto-slide unless it's the video slide
  useEffect(() => {
    if (bannerImages[currentIndex] === aws) {
      return; // Stop auto-slide on video slide
    }
    const interval = setInterval(nextSlide, 6000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  // Ensure the video plays from the start when it becomes the current slide
  useEffect(() => {
    if (bannerImages[currentIndex] === aws && videoRef.current) {
      videoRef.current.currentTime = 0; // Reset video time
      videoRef.current.play().catch((error) => {
        console.error("Error playing video:", error);
      });
    }
  }, [currentIndex]);

  // Handle video play and end
  const handleVideoPlay = () => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Error playing video:", error);
      });
    }
  };

  const handleVideoEnd = () => {
    nextSlide(); // Move to the next slide after video ends
  };

  // Set up intersection observer for section animations
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
            observer.unobserve(entry.target); // Stop observing once the class is added
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    const currentSections = sectionsRef.current;

    currentSections.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      currentSections.forEach((section) => {
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, []);

  return (
    <div className='home-container'>
      <nav className='navbar'>
        <div className='logo-container'>
          <img src={awsLogo1} alt='AWS Logo 1' className='logo1' />
          <img src={awsLogo2} alt='AWS Logo 2' className='logo1' />
        </div>
        <button className='menu-toggle' onClick={() => setMenuOpen(!menuOpen)}>
          &#9776;
        </button>
        <ul className={`nav-links ${menuOpen ? "open" : ""}`}>
          <li>
            <a href='#banner'>Banner</a>
          </li>
          <li>
            <a href='#about'>About</a>
          </li>
          <li>
            <a href='#events'>Events</a>
          </li>
          <li>
            <a href='#speakers'>Speakers</a>
          </li>
          <li>
            <a href='#save-spot'>Save a Spot</a>
          </li>
          <li>
            <a href='#opportunities'>Opportunities</a>
          </li>
          <li>
            <a href='#organizers'>Organizers</a>
          </li>
          <li>
            <a href='#contact'>Contact</a>
          </li>
          <li>
            <a href='https://docs.google.com/document/d/1pTdow3iI3BptLDu8M6zajJlQEKsm9wGLTiozv_vvnY4/edit'>
              FAQ
            </a>
          </li>
        </ul>
      </nav>

      <section
        id='banner'
        className='banner-section fade-in'
        style={{
          backgroundImage:
            typeof bannerImages[currentIndex] === "string" &&
            bannerImages[currentIndex].endsWith(".mp4")
              ? "none"
              : `url(${bannerImages[currentIndex]})`,
          backgroundRepeat: "no-repeat",
        }}
        ref={(el) => (sectionsRef.current[0] = el)}>
        {typeof bannerImages[currentIndex] === "string" &&
          bannerImages[currentIndex].endsWith(".mp4") && (
            <div>
              <video
                ref={videoRef}
                src={bannerImages[currentIndex]}
                controls={false}
                className='video-banner'
                onEnded={handleVideoEnd}
                onPlay={handleVideoPlay}
                muted
              />
            </div>
          )}
        <div className='banner-overlay'>
          <div className='banner-content'>
            <h1>Uniting Innovators Across West Africa</h1>
            <p className='banner-description'>
              Welcome to the official website for AWS Community Day West Africa
              2024! This year’s theme is "Next-Gen Solutions: Uniting AWS
              Infrastructure, Local Zones, and Generative AI for Global Impact".
              Join us for an incredible event that brings together developers,
              cloud enthusiasts, and industry leaders from across West Africa
              and beyond.
            </p>
            <h2>
              Date: September 27 (Virtual Workshop)-28 (Hybrid Event), 2024
            </h2>
            <div className='leader'>
              <h2>
                Location: Queen's Park Event Center located at Corporation{" "}
                <br />
                Drive, Off Ligali Ayorinde Street, Oniru, Victoria Island,
                Lagos, Nigeria.
              </h2>
            </div>

            <div className='banner_btn'>
              <a
                href='https://www.youtube.com/live/tcMJiBGpBLM'
                className='register-button'
                style={{ backgroundColor: "#AC65FF" }}
                target='blank'>
                Join Day One Keynote Room
              </a>
              <a
                href='https://www.youtube.com/live/tk2_7ybYZiQ?si=YoSP_sTsWA0k7MYl'
                className='register-button '
                style={{ backgroundColor: "#E52F5B" }}
                target='blank'>
                Join Room One
              </a>
              <a
                href='https://www.youtube.com/live/UHTfv4GaoQg?si=cmXzvUCaw67PR4Az'
                className='register-button'
                style={{ backgroundColor: "#FF9900" }}
                target='blank'>
                Join Room Two
              </a>

              <a href='/registration' className='register-button '>
                Register Now
              </a>
              <a
                href='https://forms.gle/eCqmqFnyyYdrh9Sn6'
                className='register-button-diff '>
                Sponsor
              </a>
            </div>
          </div>
          <div className='carousel-navigation'>
            <button className='carousel-button left' onClick={prevSlide}>
              &#9664;
            </button>
            <button className='carousel-button right' onClick={nextSlide}>
              &#9654;
            </button>
          </div>
          <div className='carousel-indicators'>
            {bannerImages.map((_, index) => (
              <span
                key={index}
                className={`indicator ${
                  currentIndex === index ? "active" : ""
                }`}
                onClick={() => goToSlide(index)}></span>
            ))}
          </div>
        </div>
      </section>
      <section
        id='about'
        className='about-section fade-in'
        ref={(el) => (sectionsRef.current[1] = el)}>
        <h2 className='section-header'>About AWS Community Day West Africa</h2>
        <p className='section-description'>
          AWS Community Day West Africa is a FREE event designed to provide a
          platform for sharing knowledge, networking, and showcasing innovative
          solutions built on AWS. <br /> This event is organized by the AWS
          Cloud Security User Group West Africa and AWS Nigeria User Group and
          is supported by AWS Nigeria.
        </p>
        <div className='cards-container'>
          <div className='card card-align '>
            <h3>Introduction</h3>
            <ul className='about-list-holder'>
              <li className='about-list'>
                The event will cover topics such as AWS infrastructure, Local
                Zones, and generative AI.
              </li>
              <li className='about-list'>
                Speakers include AWS experts, security professionals, and
                thought leaders with experience in cloud security and
                innovation.
              </li>
              <li className='about-list'>
                The event features interactive sessions, hands-on workshops, and
                networking opportunities to help attendees build their skills
                and knowledge.
              </li>
              <li className='about-list'>
                Our goal is to equip participants with the knowledge and skills
                to effectively utilize AWS Infrastructure, Local Zones, and
                Generative AI, empowering them to leverage these cutting-edge
                tools for business expansion in the evolving tech landscape.
              </li>
            </ul>
          </div>
          <div className='card'>
            <h3>What to Expect</h3>
            <ul className='about-list-holder'>
              <li className='about-list'>
                Keynote Sessions: Learn from AWS experts and industry leaders
                about the latest advancements in cloud computing, AI, and local
                infrastructure.
              </li>
              <li className='about-list'>
                Technical Workshops: Participate in hands-on workshops and
                breakout sessions tailored to both beginners and advanced users.
              </li>
              <li className='about-list'>
                Panel Discussions: Engage with AWS Solution Architects and
                customers as they discuss real-world use cases and best
                practices.
              </li>
              <li className='about-list'>
                Networking Opportunities: Connect with like-minded
                professionals, potential partners, and AWS customers to expand
                your network and explore new opportunities.
              </li>
              <li className='about-list'>
                Exhibitor Booths: Visit booths from AWS partners showcasing
                their latest products and services.
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section
        id='events'
        className='events-section fade-in'
        ref={(el) => (sectionsRef.current[2] = el)}>
        <h2 className='section-header'>Event Highlights</h2>
        <div className='event-body'>
          <div className='event-card width-event'>
            <h3>Day 1: Virtual Technical Workshops</h3>
            <p className='event-description'>
              Kick off the event with a series of virtual workshops featuring
              two breakout rooms. Each room will host four 40-minute sessions
              covering a range of technical topics around our theme. This is a
              great opportunity to dive deep into specific areas of interest and
              enhance your technical skills.
            </p>
          </div>
          <div className='event-card width-event'>
            <h3>Day 2: Keynote and Panel Sessions(Virtual & In-Person)</h3>
            <p className='event-description'>
              Join us for two inspiring keynote sessions focused on both
              technical and community topics. Participate in panel discussions
              featuring AWS Solutions Architects and industry experts, and learn
              about customer-focused use cases and solutions.
            </p>
          </div>
        </div>
      </section>

      <section
        id='speakers'
        className='speakers-section fade-in'
        ref={(el) => (sectionsRef.current[3] = el)}>
        <h2 className='section-header'>Speakers</h2>
        <p>
          We are honored to host a lineup of distinguished speakers, including
          AWS Heroes, Principal Solutions Architects, <br /> and community
          leaders. Stay tuned for more details about our keynote speakers and
          panelists.
        </p>

        <div className='day_one'>
          <h2 className='day-header'>Day 1</h2>

          <div className='key-note'>
            <h2 className='day-header'>Keynote Speaker</h2>
            <div className='speaker-card'>
              <img src={cyril} alt='Speaker' />

              <div className='speaker-module'>
                <h2>Cyril Bandolo</h2>
                <h6>Senior Serverless Developer for Serverless Guru</h6>

                <div className='speaker-topic'>
                  <p>The road to becoming a Next-Gen Developer</p>
                </div>
              </div>
            </div>
          </div>
          <div className='room_one'>
            <h2 className='day-header'>Room One</h2>
            <div className='speaker-cards'>
              <div className='speaker-card'>
                <img src={monika} alt='Speaker' />

                <div className='speaker-module'>
                  <h2>Monika Rajput</h2>
                  <h6>Senior Data and Cloud Engineer @Ahead</h6>

                  <div className='speaker-topic'>
                    <p>Unlock Data Engineering with AWS Services</p>
                  </div>
                </div>
              </div>
              <div className='speaker-card'>
                <img src={justice} alt='Speaker' />

                <div className='speaker-module'>
                  <h2>Justice Akpati</h2>
                  <h6>Information Security Officer at GTCO</h6>

                  <div className='speaker-topic'>
                    <p>
                      Integrating AI-Driven Threat Detection in AWS Environments
                    </p>
                  </div>
                </div>
              </div>
              <div className='speaker-card'>
                <img src={ayomide} alt='Speaker' />

                <div className='speaker-module'>
                  <h2>Ayomide Odukoya</h2>
                  <h6>
                    Senior Security Engineer at Resilient Software Security
                  </h6>

                  <div className='speaker-topic'>
                    <p>
                      AI as the 24/7, 'Omnipresent' Security Champion for all
                      your teams
                    </p>
                  </div>
                </div>
              </div>
              <div className='speaker-card'>
                <img src={jane} alt='Speaker' />

                <div className='speaker-module'>
                  <h2>Witah Jane</h2>
                  <h6>Technical Lead & AWS Cloud Developer at Yogaya Inc.</h6>

                  <div className='speaker-topic'>
                    <p>
                      Navigating Career Transitions in Tech and Integrating
                      Generative AI for Innovative Solutions
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='room_one'>
            <h2 className='day-header'>Room Two</h2>
            <div className='speaker-cards'>
              <div className='speaker-card'>
                <img src={precious} alt='Speaker' />

                <div className='speaker-module'>
                  <h2>Precious Okwu</h2>
                  <h6>Lead Platform Engineer at Lykon</h6>

                  <div className='speaker-topic'>
                    <p>Startup Cloud Security Model</p>
                  </div>
                </div>
              </div>
              <div className='speaker-card'>
                <img src={samuel} alt='Speaker' />

                <div className='speaker-module'>
                  <h2>Samuel Kehinde Ayo</h2>
                  <h6>Lead AI Engineer | Clive Alliance, Florida USA</h6>

                  <div className='speaker-topic'>
                    <p>Building Agentic AI Solutions With Custom Tools</p>
                  </div>
                </div>
              </div>
              <div className='speaker-card'>
                <img src={adijat} alt='Speaker' />

                <div className='speaker-module'>
                  <h2>Adijat Tosin Ojutomori</h2>
                  <h6>Backend and Data Engineer at Cecure Intelligence Ltd</h6>

                  <div className='speaker-topic'>
                    <p>
                      Building Serverless Applications: Best Practices and
                      Pitfalls
                    </p>
                  </div>
                </div>
              </div>
              <div className='speaker-card'>
                <img src={chisom} alt='Speaker' />

                <div className='speaker-module'>
                  <h2>Chisom Jude</h2>
                  <h6>Senior SRE at Interswitch</h6>

                  <div className='speaker-topic'>
                    <p>
                      Leveraging AWS Local Zones for Low-Latency and Resilient
                      Applications
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='day_two'>
          <h2 className='day-header'>Day 2</h2>

          <div className='key-note'>
            <h2 className='day-header'>Keynote Speakers</h2>
            <div className='speaker-cards'>
              <div className='speaker-card'>
                <img src={rachel} alt='Speaker' />

                <div className='speaker-module'>
                  <h2> Rachel Adeshina</h2>
                  <h6> Chief Technology Officer – First Bank</h6>

                  <div className='speaker-topic'>
                    <p>
                      Driving innovation and digital transformation in africa’s
                      financial sector: The role of cloud and AI in shaping the
                      future
                    </p>
                  </div>
                  <div className='speaker-session'>
                    <h5 className='session_number'>Keynote Speaker One</h5>
                  </div>
                </div>
              </div>

              <div className='speaker-card'>
                <img src={steven} alt='Speaker' />

                <div className='speaker-module'>
                  <h2>Stephen Howell</h2>
                  <h6>Academic Developer Advocate at AWS</h6>

                  <div className='speaker-topic'>
                    <p>
                      Cloud Capitals: From Dublin to Lagos, how Cloud Transforms
                      Communities
                    </p>
                  </div>
                  <div className='speaker-session'>
                    <h5 className='session_number'>Keynote Speaker Two</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='room_one'>
            <h1>Session One</h1>

            <p>State of the Community by Community</p>

            <div className='speaker-cards'>
              <div className='speaker-card'>
                <img src={org_10} alt='Speaker' />

                <div className='speaker-module'>
                  <h2>Blessing Ogechi Nwojiji</h2>

                  <h6>Sales Director for West Africa at Atomic Computing</h6>
                  <div className='speaker-topic'>
                    <p>State of the Community by Community</p>
                  </div>
                  <div className='speaker-session'>
                    <h5 className='session_number'>Session One</h5>
                  </div>
                </div>
              </div>

              <div className='speaker-card'>
                <img src={org_3} alt='Speaker' />

                <div className='speaker-module'>
                  <h2>Kenneth Nwokoro</h2>

                  <h6>COO at Spitial Labs</h6>

                  <div className='speaker-topic'>
                    <p>State of the Community by Community</p>
                  </div>
                  <div className='speaker-session'>
                    <h5 className='session_number'>Session One</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='room_one'>
            <h1>Session Two</h1>

            <p>
              {" "}
              Deploying Generative AI for Data-Driven Decision Making in Fintech
              Using AWS
            </p>

            <div className='speaker-cards'>
              <div className='speaker-card'>
                <img src={quagraine} alt='Speaker' />

                <div className='speaker-module'>
                  <h2>David Quagraine</h2>

                  <h6>Cloud Consultant</h6>
                  <div className='speaker-topic'>
                    <p>
                      Deploying generative AI for data-driven decision making in
                      fintech using AWS
                    </p>
                  </div>
                  <div className='speaker-session'>
                    <h5 className='session_number'>Session Two</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='room_one'>
            <h1>Case Study Talk</h1>

            <p>
              {" "}
              Transforming Banking with AWS and Generative AI: A Case Study by
              Yinka Daramola
            </p>
            <div className='speaker-cards'>
              <div className='speaker-card'>
                <img src={yinka} alt='Speaker' />

                <div className='speaker-module'>
                  <h2>Yinka Daramola</h2>

                  <h6>Cloud Consultant, CEO at Qucoon</h6>

                  <div className='speaker-topic'>
                    <p>
                      Transforming Banking with AWS and Generative AI: A Case
                      Study by Yinka Daramola
                    </p>
                  </div>
                  <div className='speaker-session'>
                    <h5 className='session_number'>Session Two</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='room_one'>
            <h1>Panel Session One</h1>

            <p>
              {" "}
              Building Resilient and High-Performing Applications with AWS
              Infrastructure and Local Zones
            </p>

            <div className='speaker-cards'>
              <div className='speaker-card'>
                <img src={ekefre} alt='Speaker' />

                <div className='speaker-module'>
                  <h2>David Ekefre</h2>

                  <h6>CRO at CloudPlexo</h6>
                  <div className='speaker-topic'>
                    <p>
                      Building resilient and high-performing applications with
                      AWS infrastructure and local zones
                    </p>
                  </div>
                  <div className='speaker-session'>
                    <h5 className='session_number'>Panel Session One</h5>
                  </div>
                </div>
              </div>

              <div className='speaker-card'>
                <img src={ewere} alt='Speaker' />

                <div className='speaker-module'>
                  <h2>Ewere Enyaosha</h2>

                  <h6>Head of Information & Communication Technology</h6>

                  <div className='speaker-topic'>
                    <p>
                      Building resilient and high-performing applications with
                      AWS infrastructure and local zones
                    </p>
                  </div>
                  <div className='speaker-session'>
                    <h5 className='session_number'>Panel Session One</h5>
                  </div>
                </div>
              </div>

              <div className='speaker-card'>
                <img src={mayowa} alt='Speaker' />

                <div className='speaker-module'>
                  <h2>Mayowa Amao</h2>

                  <h6>Enterprise Solutions Architect at AWS</h6>
                  <div className='speaker-topic'>
                    <p>
                      Building resilient and high-performing applications with
                      AWS infrastructure and local zones
                    </p>
                  </div>
                  <div className='speaker-session'>
                    <h5 className='session_number'>Panel Session One</h5>
                  </div>
                </div>
              </div>

              <div className='speaker-card'>
                <img src={imoh} alt='Speaker' />

                <div className='speaker-module'>
                  <h2>Imoh Etuk</h2>

                  <h6>Senior Solutions Architect at IT Horizons Holdings</h6>
                  <div className='speaker-topic'>
                    <p>
                      Building resilient and high-performing applications with
                      AWS infrastructure and local zones
                    </p>
                  </div>
                  <div className='speaker-session'>
                    <h5 className='session_number'>Panel Session One</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='room_one'>
            <h1>Panel Session Two</h1>

            <p>
              {" "}
              Unlocking Innovation with Generative AI on AWS: Use Cases and
              Success Stories
            </p>

            <div className='speaker-cards'>
              <div className='speaker-card'>
                <img src={oladele} alt='Speaker' />

                <div className='speaker-module'>
                  <h2>Oladele Oluwadamilare</h2>

                  <h6>Datamellon Representative</h6>
                  <div className='speaker-topic'>
                    <p>
                      Unlocking Innovation with Generative AI on AWS: Use Cases
                      and Success Stories
                    </p>
                  </div>
                  <div className='speaker-session'>
                    <h5 className='session_number'>Panel Session Two</h5>
                  </div>
                </div>
              </div>

              <div className='speaker-card'>
                <img src={fikayo} alt='Speaker' />

                <div className='speaker-module'>
                  <h2>Fikayo Olagunju</h2>

                  <h6>Descasio Limited Representative</h6>

                  <div className='speaker-topic'>
                    <p>
                      Unlocking Innovation with Generative AI on AWS: Use Cases
                      and Success Stories
                    </p>
                  </div>
                  <div className='speaker-session'>
                    <h5 className='session_number'>Panel Session Two</h5>
                  </div>
                </div>
              </div>

              <div className='speaker-card'>
                <img src={ewere2} alt='Speaker' />

                <div className='speaker-module'>
                  <h2>Ewere Diagboya</h2>

                  <h6>AWS Hero</h6>
                  <div className='speaker-topic'>
                    <p>
                      Unlocking Innovation with Generative AI on AWS: Use Cases
                      and Success Stories
                    </p>
                  </div>
                  <div className='speaker-session'>
                    <h5 className='session_number'>Panel Session Two</h5>
                  </div>
                </div>
              </div>

              <div className='speaker-card'>
                <img src={felix} alt='Speaker' />

                <div className='speaker-module'>
                  <h2>Felix Frank-Felix</h2>

                  <h6>Solutions Architect at Qucoon</h6>
                  <div className='speaker-topic'>
                    <p>
                      Unlocking Innovation with Generative AI on AWS: Use Cases
                      and Success Stories
                    </p>
                  </div>
                  <div className='speaker-session'>
                    <h5 className='session_number'>Panel Session Two</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='room_one'>
            <h1>Session Three</h1>

            <p>
              {" "}
              Unleashing Potential, Mitigating Risks: The Ethical Landscape of
              Generative AI
            </p>

            <div className='speaker-cards'>
              <div className='speaker-card'>
                <img src={elizabeth} alt='Speaker' />

                <div className='speaker-module'>
                  <h2>Elizabeth Adeotun</h2>

                  <h6>Cloud Consultant at Public Cloud Group (PCG) GmbH</h6>
                  <div className='speaker-topic'>
                    <p>
                      Unleashing Potential, Mitigating Risks: The Ethical
                      Landscape of Generative AI
                    </p>
                  </div>
                  <div className='speaker-session'>
                    <h5 className='session_number'> Session Three</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='room_one'>
            <h1>Session Four</h1>

            <p>
              {" "}
              Orchestrating AI Workflows: Integrating AWS Step Functions with
              Bedrock
            </p>

            <div className='speaker-cards'>
              <div className='speaker-card'>
                <img src={chibuike} alt='Speaker' />

                <div className='speaker-module'>
                  <h2>Chibuike Nwachukwu</h2>

                  <h6>Full stack developer and AWS community builder</h6>
                  <div className='speaker-topic'>
                    <p>
                      Orchestrating AI Workflows: Integrating AWS Step Functions
                      with Bedrock
                    </p>
                  </div>
                  <div className='speaker-session'>
                    <h5 className='session_number'> Session Four</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id='save-spot'
        className='save-spot-section fade-in'
        ref={(el) => (sectionsRef.current[6] = el)}>
        <h2 className='section-header'>Save Your Spot!</h2>
        <p>
          Save your spot for AWS Community Day West Africa 2024 by registering
          now. Join us for two days of immersive learning, collaboration, and{" "}
          <br /> networking with cloud professionals and technology enthusiasts
          from across the region.
        </p>
        <a href='/registration' className='register-button'>
          Register Now
        </a>
      </section>
      <section
        id='opportunities'
        className='opportunities-section fade-in'
        ref={(el) => (sectionsRef.current[4] = el)}>
        <h2 className='section-header'>Sponsorship Opportunities</h2>
        <p>
          AWS Community Day West Africa 2024 offers a unique platform for
          sponsors to connect with a diverse audience of cloud professionals and
          technology enthusiasts. <br /> If you are interested in sponsoring the
          event, please contact us for more information.
        </p>
        <div className='sponsors'>
          <h3 className='sponsor-header'>Corporate Sponsors:</h3>
          <div className='sponsor_card_container'>
            <img src={aws_white} alt='AWS' />
            <img src={copSpons2} alt='Charis Cloud' />
            <img src={atomic_light} alt='atomic_computing' />
            <img src={brainbox} alt='brainbox' />
            <img src={cloudplexo} alt='cloudplexo' />
            <img src={creditWave} alt='creditwave' />
            <img src={decasio} alt='decasio' />
            <img src={Digitspot} alt='digispot' />
            <img src={Feitian} alt='Feitian' />
            <img src={PipeOps} alt='PipeOps' />
            <img src={techguru} alt='techguru' />
            <img src={rubies} alt='rubies' />
            <img src={akolagtec} alt='rubies' />
            <img src={arravo} alt='rubies' />
            <img src={fincra} alt='rubies' />
            <img src={datamellom} alt='rubies' />
            <img src={devops} alt='rubies' />
            <img src={nycn} alt='rubies' />
          </div>

          <h3 className='sponsor-header'>Partners:</h3>
          <div className='sponsor_card_container'>
            <img src={TechMoon} alt='techmoon' />
            <img src={Shutters} alt='techmoon' />
          </div>

          <h3 className='sponsor-header'>VIP Sponsors:</h3>
          <div className='sponsor_card_container'>
            <div>
              <div className='sponsor-card'>
                <img src={spons_5} alt='Sponsor' />
              </div>
              <div>
                <h2>Promise Nwachukwu</h2>
              </div>
            </div>
            <div>
              <div className='sponsor-card'>
                <img src={spons_6} alt='Sponsor' />
              </div>
              <div>
                <h2>Tayo Fatoki</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id='organizers'
        className='opportunities-section fade-in'
        ref={(el) => (sectionsRef.current[5] = el)}>
        <h2 className='section-header'>Organizers</h2>
        <p>
          AWS West Africa User Group Community commends our selfless and
          hardworking organizers working relentlessly day and night towards
          making this event a success.
        </p>
        <div className='organizers'>
          <div className='organizers_card_container'>
            <div>
              <div className='organizers-card'>
                <img src={org_10} alt='Sponsor' />
              </div>
              <div>
                <h2 className='org-name'>Blessing Ogechi Nwojiji </h2>
                <p className='leader'>User Group Leader</p>
              </div>
            </div>

            <div>
              <div className='organizers-card'>
                <img src={org_7} alt='Sponsor' />
              </div>
              <div>
                <h2 className='org-name'>Ewere Diagboya</h2>
                <p className='leader'>AWS Hero</p>
              </div>
            </div>
            <div>
              <div className='organizers-card'>
                <img src={org_1} alt='Sponsor' />
              </div>
              <div>
                <h2 className='org-name'>Adeleke Tolulope</h2>
                <p className='leader'>User Group Leader</p>
              </div>
            </div>
            <div>
              <div className='organizers-card'>
                <img src={org_3} alt='Sponsor' />
              </div>
              <div>
                <h2 className='org-name'>Kenneth Nwokoro</h2>
                <p className='leader'>User Group Leader</p>
              </div>
            </div>
            <div>
              <div className='organizers-card'>
                <img src={org_4} alt='Sponsor' />
              </div>
              <div>
                <h2 className='org-name'>Jamiu Ayinde</h2>
                <p className='leader'>User Group Leader</p>
              </div>
            </div>
            <div>
              <div className='organizers-card'>
                <img src={org_5} alt='Sponsor' />
              </div>
              <div>
                <h2 className='org-name'>Imoh Etuk</h2>
                <p className='leader'>User Group Leader</p>
              </div>
            </div>

            <div>
              <div className='organizers-card'>
                <img src={org_14} alt='Sponsor' />
              </div>
              <div>
                <h2 className='org-name'>Wale Adeniji</h2>
                <p className='leader'>User Group Leader</p>
              </div>
            </div>
            <div>
              <div className='organizers-card'>
                <img src={spons_2} alt='Sponsor' />
              </div>
              <div>
                <h2 className='org-name'>Ejiroghene Pepper</h2>
                <p className='leader'>User Group Leader</p>
              </div>
            </div>
            <div>
              <div className='organizers-card'>
                <img src={akimi} alt='Sponsor' />
              </div>
              <div>
                <h2 className='org-name'>Akim Olu</h2>
                <p className='leader'>User Group Leader</p>
              </div>
            </div>

            <div>
              <div className='organizers-card'>
                <img src={org_9} alt='Sponsor' />
              </div>
              <div>
                <h2 className='org-name'>David Okeyode</h2>
                <p className='leader'>User Group Leader</p>
              </div>
            </div>
            <div>
              <div className='organizers-card'>
                <img src={spons_5} alt='Sponsor' />
              </div>
              <div>
                <h2 className='org-name'>Promise Nwachukwu</h2>
                <p className='leader'>User Group Leader</p>
              </div>
            </div>
            <div>
              <div className='organizers-card'>
                <img src={spons_6} alt='Sponsor' />
              </div>
              <div>
                <h2 className='org-name'>Tayo Fatoki</h2>
                <p className='leader'>User Group Leader</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section
        id='overview'
        className='overview-section fade-in'
        ref={(el) => (sectionsRef.current[7] = el)}>
        <h2 className='section-header'>Last Year's Highlight</h2>
        <p>
          From our demographic data, the event recorded over 500 professionals
          and over 150 students for in-person attendance. <br /> In addition to
          the physical attendees, over 20 students and 70 professionals joined
          virtually.
        </p>
        <div className='collage'>
          {collageImages.map((src, index) => (
            <div key={index} className='collage-item'>
              <img src={src} alt='Event' />
            </div>
          ))}
        </div>
      </section> */}

      <section
        id='contact'
        className='contact-section fade-in'
        ref={(el) => (sectionsRef.current[9] = el)}>
        <h2 className='section-header'>Contact Us</h2>
        <p>Blessing@awswestafricausergroup.info</p>
        <div className='contact-icons'>
          <a href='https://rebrand.ly/awssecwestafrica-twitter'>
            <i className='fab fa-twitter'></i> Twitter
          </a>
          <a href='https://rebrand.ly/awssecwestafrica-linkedin'>
            <i className='fab fa-linkedin'></i> LinkedIn
          </a>
          <a href='https://rebrand.ly/awssecwestafrica-youtube'>
            <i className='fab fa-youtube'></i> Youtube
          </a>
          <a href='https://rebrand.ly/awssecwestafrica-medium'>
            <i className='fab fa-medium'></i> Medium
          </a>
          <a href='https://rebrand.ly/awssecwestafrica-meetup'>
            <i className='fab fa-meetup'></i> Meetup
          </a>
          <a href='https://www.instagram.com/awscommunitydaywestafrica/'>
            <i className='fab fa-instagram'></i> Instagram
          </a>
          <a href='https://www.tiktok.com/@aws_community_day_wa?is_from_webapp=1&sender_device=pc'>
            <i className='fab fa-tiktok'></i> Tiktok
          </a>
        </div>

        <p>
          Hosted by: AWS Cloud Security User Group West africa and AWS Nigeria
          User Group
        </p>

        <div>
          <img src={awsLogo1} alt='AWS Logo 1' className='logo1' />
          <img src={awsLogo2} alt='AWS Logo 2' className='logo1' />
        </div>

        <div className='map'>
          <a
            href='https://maps.app.goo.gl/3rNBsXHu9fRpoBtbA'
            className='register-button-maps'>
            View maps
          </a>
        </div>

        <button
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            padding: "10px 20px",
            fontSize: "16px",
            backgroundColor: "#FF9900",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          }}>
          Back to Top
        </button>

        <p>&#169; 2024 awsusergroupwestafrica, All Rights Reserved.</p>
      </section>
    </div>
  );
};

export default Home;
